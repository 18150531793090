<template>
    <b-card class="mb-2 input-group-alternative" :class="{'shadow': hover && shadow}" @mouseover="hover = true;" @mouseleave="hover = false;">
        <template v-slot:header>
            <a href="javascript:void(0);" @click="$gtag.event('see_property'); $router.push('/property/' + property._id)">
                <h5 class="mb-0" style="font-size: small; font-weight: bold; text-transform:uppercase">
                    {{$t('reply.request.property.' + property.property)}} <span v-if="property.type == 'buy'">{{$t('find.type.buy')}}</span><span v-else>{{$t('find.type.rent')}}</span>
                </h5>
            </a>
        </template>
        <b-card-title class="col-md-auto" style="font-size:large;">
            {{property.location.slice(0, property.location.indexOf(',', property.location.indexOf('-')))}}
        </b-card-title>
        <b-card-text>
            <b-carousel v-if="property.images.length > 0 && showImg"
                        id="carousel-1"
                        :key="property._id"
                        :controls="false"
                        :interval="0"
                        :indicators="false">                
                <b-carousel-slide>
                    <template #img>
                        <div class="border-top border-bottom">
                            <a href="javascript:void(0);" @click="cover = !cover">
                                <img :class="{'cover': cover, 'contain': !cover}" @error="showImg = false;" :src="property.images[0].path">
                            </a>
                        </div>
                    </template>
                </b-carousel-slide>
            </b-carousel>
            <div>
                <div class="mt-3 justify-content-md-center">
                    <span v-if="property.amount" class="col-md-auto lead" style="font-weight: 500;">{{$t('global.currency.real')}} {{property.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}}</span>
                    <span v-else class="col-md-auto lead" style="font-weight: 500;">Valor sob consulta</span>
                    <span class="col-md-auto lead" style="font-weight: 500;">{{property.area}} {{$t('global.meter')}}</span>
                </div>
            </div>
            <!--<div v-if="property.rent_time > 0" class="mt-2 row justify-content-md-center">
                <span class="col-md-auto">{{$tc('response.time', property.rent_time, {quantity: property.rent_time})}}</span>
            </div>-->
            <div class="mt-2 justify-content-md-center lead">
                <!--<badge class="goodge" v-if="property.floor > 0" type="primary">
                    <i class="fa fa-building" aria-hidden="true"></i>
                    {{$tc('response.floor', property.floor, { quantity: parseInt(property.floor) })}}
                </badge>-->
                <span class="badge goodge" v-if="property.suite > 0" type="primary">
                    <i class="fa fa-bath" aria-hidden="true"></i>
                    {{$tc('response.suite', property.suite, { quantity: parseInt(property.suite) })}}
                </span>
                <span class="badge goodge" v-if="property.room > 0" type="primary">
                    <i class="fa fa-bed" aria-hidden="true"></i>
                    {{$tc('response.room', property.room, { quantity: parseInt(property.room) })}}
                </span>
                <span class="badge goodge" v-if="property.garage > 0" type="primary">
                    <i class="fa fa-car" aria-hidden="true"></i>
                    {{$tc('response.garage', property.garage, { quantity: parseInt(property.garage) })}}
                </span>
                <span class="badge goodge" v-if="property.furnished" type="primary">
                    <i class="fa fa-television" aria-hidden="true"></i>
                    {{$t('response.furnished')}}
                </span>
                <span class="badge goodge" v-if="property.pet != '0'" type="primary">
                    <i class="fa fa-paw" aria-hidden="true"></i>
                    {{$tc('response.pet', property.pet)}}
                </span>
            </div>
        </b-card-text>
        <template v-slot:footer>
            <div v-if="property.feature">
                <div v-if="property.feature.length > 1">
                    <!--<div class="row">
                        <small class="col colText">{{$t('response.feature')}}</small>
                    </div>-->
                    <div class="row">
                        <div class="col colText">
                            <span v-for="(feat, n) in property.feature.slice(0, 5)" :key="n">
                                <span class="badge">{{feat}}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="mt-4" v-if="property.detail">
                <div class="row">
                    <div class="col colText">
                        <i class="fa fa-quote-left fa-1" aria-hidden="true"></i>
                    </div>
                </div>
                <div class="row">
                    <div class="col colText">
                        {{property.detail}}
                    </div>
                </div>
            </div>-->
            <div>
                <div class="mt-2 justify-content-center">
                    <base-button class="mt-2 justify-content-center" type="success" icon="fa fa-whatsapp my-float" @click="$emit('getPropertyId', property._id); $gtag.event('contact_realtor');">
                        Ver contato
                    </base-button>
                    <a class="row justify-content-center mt-2" style="color: black;" href="javascript:void(0);" @click="$gtag.event('see_property'); $router.push('/property/' + property._id)">Ver detalhes</a>
                </div>
                <!--<div class="mt-3">
                    <small>
                        <i>
                            <div v-if="property.static">{{$t('reply.request.warning.static')}}</div>
                            <div v-else>{{$t('reply.request.warning.dynamic')}}</div>
                        </i>
                    </small>
                </div>-->
            </div>
        </template>
    </b-card>
</template>
<script>
    import BaseButton from '../components/BaseButton'
    import {
        BCard,
        BCardText,
        BCardTitle,
        BCarousel,
        BCarouselSlide
    } from 'bootstrap-vue'
    //import $ from 'jquery';
    export default {
        name: "property-preview",
        components: {
            BaseButton,
            BCard,
            BCardText,
            BCardTitle,
            BCarousel,
            BCarouselSlide
        },
        props: {
            property: {
                type: Object,
                description: "Property data",
            },
            shadow: { default: true },
            type: { default: 'reply' },
            request_id: {},
        },
        data() {
            return {
                hover: false,
                desktop: screen.width > 700,
                cover: true,
                showImg: true,
            }
        },
        mounted() {
            this.$gtag.set({ 'user_id': this.$store.state._id });
        }
    };
</script>
<style scoped>
    .card-body {
        padding-left: 0em;
        padding-right: 0em;
    }

    .colText {
        text-align: left;
    }

    .goodge {
        font-size: 16px;
        font-weight:500;
        margin: 5px;
        text-transform: lowercase;
    }

    .cover {
        object-fit: cover;
        height: 300px;
        width: 100%
    }

    .contain {
        object-fit: contain;
        height: 300px;
        width: 100%
    }
</style>