<template>
    <card type="primary" shadow
          header-classes="bg-white pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0"
          style="text-align: center;"
          id="register">
        <template>            
            <form role="form" class="text-white">
                <div v-if="message" class="pb-4">
                    <span class="lead">{{message}}</span>
                </div>

                <b-form-group>
                    <b-form-radio-group v-model="$store.state.filter.type" buttons :options="options">
                    </b-form-radio-group>
                </b-form-group>

                <div class="mt-5 col" v-show="$store.state.filter.type == 'buy'">
                    <vue-slider :tooltip-formatter="v => $t('request.tooltip.currency') + `${('' + v).replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`"
                                tooltip="always"
                                v-model="$store.state.filter.amount.buy"
                                :min="min_amount['buy']"
                                :max="max_amount['buy']"
                                :min-range="10000"
                                :interval="1000">
                    </vue-slider>
                    <label style="font-weight:bold;">{{$t('request.label.price')}} de venda</label>
                </div>

                <div class="mt-5 col" v-show="$store.state.filter.type == 'rent'">
                    <vue-slider :tooltip-formatter="v => $t('request.tooltip.currency') + `${('' + v).replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`"
                                tooltip="always"
                                v-model="$store.state.filter.amount.rent"
                                :min="min_amount['rent']"
                                :max="max_amount['rent']"
                                :min-range="500"
                                :interval="$store.state.filter.amount.rent[1] > 5000 ? 100 : 50">
                    </vue-slider>
                    <label style="font-weight:bold;">{{$t('request.label.price')}} de aluguel</label>
                </div>

                <div class="mt-5 col">
                    <vue-slider :tooltip-formatter="'{value} '+ $t('request.tooltip.meter')"
                                tooltip="always"
                                v-model="$store.state.filter.area"
                                :max="max_area"
                                :min-range="5">
                    </vue-slider>
                    <label style="font-weight:bold;">{{$t('request.label.area')}}</label>
                </div>

                <div class="text-center">
                    <base-button type="secondary" class="my-4" @click="applyFilter()"><span v-if="type == 'realtor'">Encontrar clientes</span><span v-else>{{$t('find.filter.submit')}}</span></base-button>
                    <div><a class="text-white" href="javascript:void(0);" @click="$emit('closeFilter'); $gtag.event('see_all')">Ver todos os <span v-if="type == 'realtor'">clientes</span><span v-else>{{$t('find.filter.all')}}</span></a></div>
                </div>
            </form>
        </template>
    </card>
</template>

<script>
    import VueSlider from 'vue-slider-component';
    import 'vue-slider-component/theme/antd.css';
    import { BFormGroup, BFormRadioGroup } from 'bootstrap-vue';
    import $ from 'jquery';

    export default {
        name: 'FilterCard',
        components: {
            VueSlider,
            BFormGroup,
            BFormRadioGroup
        },
        props: {
            message: { default: '' },
            type: {default: 'realtor'}
        },
        data() {
            return {                
                min_amount: { rent: 0, buy: 0 },
                max_amount: { rent: 30000, buy: 4000000 },
                amount_step: { rent: 50, buy: 100 },
                min_area: 15,
                max_area: 650,

                options: [
                    { text: 'ALUGUEL', value: 'rent' },
                    { text: 'VENDA', value: 'buy' },
                ]
            }
        },
        methods: {
            applyFilter() { 
                this.$gtag.event('apply_filter');
                this.$emit('filterApplied');
            }
        },
    };
    $('input').on('keyup keypress', function (e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
            e.preventDefault();
            return false;
        }
    });
</script>
<style scoped>
    .parent.vue-tel-input {
    }

    .autocompletePlaceholder::-webkit-input-placeholder {
        color: #8898aa
    }

    div /deep/ label {
        display: unset;
    }

    label {
        font-weight: bold !important;
    }
</style>