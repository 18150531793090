<template>
    <div>
        <div ref="Map" id="map" @click="show_popover = false;" :class="[{'Gmap': true}, {'showMap': toggle_view}, {'hideMap': !toggle_view}]"></div>

        <b-button v-if="toggle_view || desktop" size="sm" variant="primary" class="filterButton btn-icon" @click="filter = true; $gtag.event('open_property_filter')"><span class="btn-inner--icon"><i class="fa fa-filter"></i></span> <span class="btn-inner--text">{{$t('find.filter.button')}}</span></b-button>

        <base-button :icon="button_icon" type="primary" id="toggleButton" size="sm" @click="toggler();">{{toggle_view ? $t('find.map.property') : $t('reply.map.map')}}</base-button>

        <!--<b-popover v-if="!$store.state.logged_in && show_popover" target="toggleButton" show placement="top">
            {{$t('notification.map.popover.property.text')}}
        </b-popover>-->

        <div id="requests" style="overflow: hidden;">
            <div :class="[{'properties': true}, {'showMap': !toggle_view}, {'hideMap': toggle_view}]">
                <div class="form-group input-group-alternative">
                    <div class="input-group-append mt-2 mb-2">
                        <input ref="Autocomplete" class="form-control form-control-alternative" placeholder="">
                        <base-button type="outline-secondary" icon="fa fa-filter" @click="filter = true; $gtag.event('open_filter')"></base-button>
                    </div>
                </div>
                <property-preview v-for="property in cards"
                                  :key="property._id"
                                  :property="property"
                                  @getPropertyId="contactRealtor(property, 'realtor')"></property-preview>
                <infinite-loading @infinite="infiniteHandler" v-if="!toggle_view" force-use-infinite-wrapper="body" :identifier="infiniteId" spinner="spiral">
                    <div slot="no-more">
                        <card type="primary"
                              shadow
                              class="mt-2 border-0"
                              v-if="!$store.state.logged_in">
                            <p class="lead text-white my-auto">
                                <span>{{$t('find.map.lost')}}</span>
                                <i18n v-if="!$store.state.logged_in" path="find.map.call" tag="a">
                                    <template v-slot:request>
                                        <router-link style="font-weight:bold; color: white;" @click="$gtag.event('want_request')" to="request">{{$t('find.map.request')}}</router-link>
                                    </template>
                                </i18n>
                            </p>
                        </card>
                    </div>
                    <div slot="no-results">
                    </div>
                </infinite-loading>
                <card v-if="cards.length == 0"
                      type="primary"
                      shadow
                      class="mt-2 border-0">
                    <p class="lead text-white my-auto">
                        <span>{{$t('find.map.empty')}}</span>
                        <i18n v-if="!$store.state.logged_in" path="find.map.call" tag="a">
                            <template v-slot:request>
                                <router-link style="font-weight:bold; color: white;" @click="$gtag.event('want_request')" to="request">{{$t('find.map.request')}}</router-link>
                            </template>
                        </i18n>
                    </p>
                </card>
            </div>
        </div>

        <modal :show.sync="modal"
               gradient="success"
               modal-classes="modal-danger modal-dialog-centered">
            <h6 slot="header" class="modal-title" id="modal-title-notification">{{$t('find.contact.info')}}</h6>

            <div class="py-3 text-center">
                <i class="ni ni-chat-round ni-4x"></i>
                <p class="mt-4">{{$t('find.contact.interest', {name: contact.name.full})}}</p>
                <i18n path="response.contact.contact" tag="p">
                    <template v-slot:phone>
                        <a style="color: white; font-weight: bold;" target="_blank" @click="$gtag.event('contact_realtor_number')" :href="(desktop ? 'https://web' : 'https://api') + '.whatsapp.com/send?phone=' + contact.number.international + '&text=' + $t('find.contact.text.whatsapp', {name: contact.name.full, location: contact.location, type: contact.type, price: contact.price})">{{contact.number.national}}</a>
                    </template>
                    <template v-slot:email>
                        <a style="color: white; font-weight: bold;" @click="$gtag.event('contact_realtor_email')" :href="'mailto:' + contact.email + '?subject=Cliente da Immobl' + '&body=' + $t('find.contact.text.whatsapp', {name: contact.name.full, location: contact.location, type: contact.type, price: contact.price})">{{contact.email}}</a>
                    </template>
                </i18n>
            </div>
        </modal>

        <modal :show.sync="filter" showClose style="z-index: 9999999999999999999999" body-classes="p-0">
            <!--<template slot="header">
                <h5 class="modal-title" style="text-align: left !important; line-height: 1.3;">
                    <strong>
                        {{$t('notification.map.popover.map.text')}}
                        <a v-if="this.$store.state.type == 'realtor' || this.$route.path == '/reply'" @click="$gtag.event('contact_us')" :href="(desktop ? 'https://web' : 'https://api') + '.whatsapp.com/send?phone=5512997531833&text=Oi. Sou corretor e gostaria de saber mais sobre a Immobl'" target="_blank">
                            {{$t('call.whatsapp')}}
                        </a>
                    </strong>
                </h5>
            </template>-->
            <filter-card @filterApplied="reloadWithFilter" @closeFilter="reloadWithoutFilter" type="requestor"></filter-card>
        </modal>

        <!--<modal v-if="desktop && !$store.state.logged_in" :show.sync="learn" style="text-align: left; z-index: 9999999999999999999999" body-classes="p-0">
            <template slot="header">
                <h5 class="modal-title">{{$t('reply.learn.title')}}</h5>
            </template>
            <section class="mb-3">
                <div class="container">
                    <div>
                        <div class="row">
                            <div class="col">
                                <p class="lead">
                                    {{$t('reply.learn.p1')}}
                                    <a v-if="this.$store.state.type == 'realtor' || this.$route.path == '/reply'" @click="$gtag.event('contact_us')" :href="(desktop ? 'https://web' : 'https://api') + '.whatsapp.com/send?phone=5512997531833&text=Oi. Sou corretor e gostaria de mais saber mais sobre a Immobl'" target="_blank">
                                        {{$t('call.whatsapp')}}
                                    </a>
                                </p>
                                <p style="font-weight: 400;">
                                    {{$t('reply.learn.p2')}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </modal>-->

    </div>
</template>

<script>
    import gmapsInit from '../utils/gmaps';
    import PropertyPreview from '../components/PropertyPreview';
    import $ from 'jquery';
    import MarkerClusterer from '@google/markerclusterer';
    import { BButton } from 'bootstrap-vue';
    import FilterCard from '../components/FilterCard';
    //import Split from 'split.js'
    import infiniteLoading from 'vue-infinite-loading';

    export default {
        name: 'Gmap',
        components: {
            PropertyPreview,
            //BPopover,
            FilterCard,
            BButton,
            infiniteLoading,
        },
        data() {
            return {
                property_ids: [],
                infiniteId: new Date(),
                page: 0,
                cards: [],
                response: 'incorrect',
                toggle_view: false,
                button_icon: 'fa fa-map-o',
                screen_height: screen.height,

                modal: false,
                filter: false,
                propertyId: '',

                contact: {
                    name: {},
                    email: '',
                    number: {},
                    location: '',
                    type: '',
                    price: '',
                },

                show_popover: true,
                learn: true,
                seen_clients: false,

                desktop: screen.width > 750,
            }
        },

        watch: {
            learn() {
                this.$gtag.event('close_modal');
            }
        },

        methods: {
            reloadInfinite() {
                this.property_ids = [];
                this.cards = [];
                this.infiniteId += 1;
                this.page = 0;
            },
            updatePropertyIds(property_ids) {
                this.property_ids = property_ids
            },
            addPage() {
               this.page += 1;
            },
            infiniteHandler($state) {                
                var addPage = this.addPage;
                var updateCard = this.updateCard;
                if (this.property_ids.length) {                    
                    var query_ids = this.property_ids.slice(this.page * 10, (this.page * 10) + 10).join(',');                    
                    if (query_ids.length) {
                        $.ajax({
                            url: 'https://api.immobl.com/property?ids=' + query_ids,
                            contentType: 'application/json;charset=UTF-8',
                            xhrFields: {
                                withCredentials: true
                            },
                            crossDomain: true,
                            type: 'GET',
                            success: function (response) {                                
                                updateCard(response.properties);
                                addPage();
                                $state.loaded();
                            }
                        });
                    } else {
                        $state.complete();
                    }
                } else {
                    $state.reset();
                }
            },
            toggler() {
                if (!this.seen_clients) {
                    this.$gtag.event('see_properties');
                    this.seen_clients = true;
                }
                this.show_popover = false;
                this.toggle_view = !this.toggle_view;
                this.button_icon = this.toggle_view ? 'fa fa-home' : 'fa fa-map-o';
            },
            updateCard(card) {
                this.cards.push(...card);
            },
            updateInput(value) {
                this.response = value;
            },
            replyTo() {
                this.$router.push({ name: 'reply', params: { request_id: this.propertyId } });
            },
            reloadWithFilter() {
                var queryObject = {
                    type: this.$store.state.filter.type,
                    amount: this.$store.state.filter.amount[this.$store.state.filter.type],
                    area: this.$store.state.filter.area,
                }

                const querystring = require('querystring');
                var queryString = querystring.stringify(queryObject);
                var currentQuery = querystring.stringify(this.$route.query);

                if (queryString != currentQuery) {
                    this.$router.push('/property?' + queryString);
                } else {
                    this.filter = false;
                }
            },
            reloadWithoutFilter() {
                this.$gtag.event('see_all');
                if (this.$route.query.type == 'all') {
                    this.filter = false;
                } else {
                    this.$router.push('/property?type=all');
                }
            },

            openContactModal() {
                this.modal = true;
            },

            updateData(key, value) {
                this.$data[key] = value;
            },

            contactRealtor(property, type) {
                var location = property.location.slice(0, property.location.indexOf(',', property.location.indexOf('-')));
                var propertyType = this.$t('find.type.' + property.type);
                var price = this.$t('global.currency.real') + ' ' + property.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                var link = 'https://immobl.com/property/' + property._id + '/%23corretor';
                var whatsapp_url = (this.desktop ? 'https://web' : 'https://api') + '.whatsapp.com/send?phone=' + property.from.number + '&text=' + this.$t('find.contact.text.whatsapp', { name: property.from.name, location: location, type: propertyType, price: price, link: link })

                if (property.from.type == 'external') {
                    this.$gtag.event('contact_realtor_external');
                    window.open(whatsapp_url, '_blank');
                } else {

                    var openContactModal = this.openContactModal;
                    var updateData = this.updateData;
                    var _id = property.from._id;

                    $.ajax({
                        url: 'https://api.immobl.com/contact/' + _id + '?type=' + type,
                        contentType: 'application/json;charset=UTF-8',
                        xhrFields: {
                            withCredentials: true
                        },
                        crossDomain: true,
                        type: 'GET',
                        success: function (resp) {
                            if (resp.document != undefined) {
                                resp.document.location = location;
                                resp.document.type = propertyType;
                                resp.document.price = price;
                                updateData('contact', resp.document);
                            }
                        },
                        //error: function (err) {
                        //    //console.log(err);
                        //}
                    });

                    openContactModal();
                }
            },
        },
        async mounted() {
            var query = this.$route.query;
            var city = this.$store.state.city;
            var search = this.$t('find.map.search');
            var isDesktop = this.desktop;
            var reloadInfinite = this.reloadInfinite;

            // Receives lat and lng on the query string and them as center of the map.
            if (query.lat && query.lng) {
                city.coordinate.lat = parseFloat(query.lat);
                city.coordinate.lng = parseFloat(query.lng);
            }

            //if ($.isEmptyObject(this.$route.query)) {
            //    this.filter = true;
            //}
            var mapUrl = this.$route.fullPath.replace('reply', 'map');
            mapUrl = this.$route.fullPath.replace('property', 'find');
            mapUrl = mapUrl.replace('?type=all', '');

            if (this.$store.state.logged_in) {
                this.$gtag.set({ 'user_id': this.$store.state._id });
            }

            const google = await gmapsInit();
            var geocoder = new google.maps.Geocoder;
            const map = new google.maps.Map(this.$refs.Map, {
                zoom: 14,
                minZoom: 12,
                center: city.coordinate,
                mapTypeControl: false,
                streetViewControl: false,
                fullscreenControl: false,
                zoomControl: this.desktop,
            });

            const input = this.$refs.Autocomplete;
            input.classList.add('autocompletePlaceholder');
            //input.placeholder = this.$store.state.city.location;
            //var defaultPlaceholder = "Enter a location";
            //map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(this.$refs.Autocomplete);
            const autocomplete = new google.maps.places.Autocomplete(
                input, { types: ['(cities)'], componentRestrictions: { country: this.$store.state.country.iso2 } });
            const markerCluster = new MarkerClusterer(map, [],
                {
                    imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
                    zoomOnClick: true,
                    minimumClusterSize: 1
                });
            autocomplete.bindTo('bounds', map);
            autocomplete.setFields(['geometry']);

            autocomplete.addListener('place_changed', function () {
                const place = autocomplete.getPlace();
                if (!place.geometry) {
                    input.value = '';
                } else {
                    input.placeholder = input.value;
                    input.value = '';
                    map.panTo(place.geometry.location);
                }
            });
            var updatePropertyIds = this.updatePropertyIds
            var component_data = this.$data;

            google.maps.event.addListener(map, 'idle', function () {
                var toggle_view = component_data.toggle_view;
                var data = map.getBounds();
                var center = map.getCenter().toJSON();

                reloadInfinite();

                // Updates the search bar with the current city in the center of the map.
                if (!toggle_view) {
                    geocoder.geocode({ 'location': center }, function (results, status) {
                        if (status === 'OK') {
                            if (results.length > 2) {
                                for (var i = results.length - 1; i > 0; i--) {
                                    if (results[i].types.includes('administrative_area_level_2') || results[i].types.includes('locality')) {
                                        var rawAddress = results[i].formatted_address.split(',')

                                        if (rawAddress.length > 1) {
                                            input.placeholder = (rawAddress[0] + ',' + rawAddress.slice().reverse()[0]).replace(' -', ',');
                                        } else {
                                            input.placeholder = rawAddress[0].replace(' -', ',');
                                        }

                                        if (!isDesktop) {
                                            var address_components = results[i].address_components;
                                            for (let i = 0; i < address_components.length; i++) {
                                                var addressTypes = address_components[i].types;

                                                if (addressTypes.includes('administrative_area_level_2')) {
                                                    data = address_components[i];

                                                    $.ajax({
                                                        url: 'https://api.immobl.com' + mapUrl,
                                                        contentType: 'application/json;charset=UTF-8',
                                                        xhrFields: {
                                                            withCredentials: true
                                                        },
                                                        crossDomain: true,
                                                        type: 'POST',
                                                        data: JSON.stringify({ data: data }),
                                                        success: function (response) {
                                                            var properties = response.properties;
                                                            //var markers = properties.map(function (property) {
                                                            //    return new google.maps.Marker({
                                                            //        position: property['coordinate'],
                                                            //    });
                                                            //});
                                                            //markerCluster.clearMarkers();
                                                            //markerCluster.addMarkers(markers);

                                                            var property_ids = properties.map(function (property) {
                                                                return property['_id']
                                                            });
                                                            updatePropertyIds(property_ids);

                                                            if (properties.length > 0) {
                                                                city.coordinate = center;
                                                            }
                                                        },
                                                        //error: function (err) {
                                                        //    //console.log(err);
                                                        //}
                                                    });
                                                    break;
                                                }
                                            }
                                        } else {
                                            if (data) {
                                                $.ajax({
                                                    url: 'https://api.immobl.com' + mapUrl,
                                                    contentType: 'application/json;charset=UTF-8',
                                                    xhrFields: {
                                                        withCredentials: true
                                                    },
                                                    crossDomain: true,
                                                    type: 'POST',
                                                    data: JSON.stringify({ data: data }),
                                                    success: function (response) {
                                                        var properties = response.properties;
                                                        var markers = properties.map(function (property) {
                                                            return new google.maps.Marker({
                                                                position: property['coordinate'],
                                                            });
                                                        });
                                                        markerCluster.clearMarkers();
                                                        markerCluster.addMarkers(markers);

                                                        var property_ids = properties.map(function (property) {
                                                            return property['_id']
                                                        });
                                                        updatePropertyIds(property_ids);

                                                        if (properties.length > 0) {
                                                            city.coordinate = center;
                                                        }
                                                    },
                                                    //error: function (err) {
                                                    //    //console.log(err);
                                                    //}
                                                });
                                            }
                                        }
                                        break
                                    }
                                }
                            }
                        }
                    });
                } else {
                    input.placeholder = search;
                    // Requests every property request within the map bounds.
                    if (data) {
                        $.ajax({
                            url: 'https://api.immobl.com' + mapUrl,
                            contentType: 'application/json;charset=UTF-8',
                            xhrFields: {
                                withCredentials: true
                            },
                            crossDomain: true,
                            type: 'POST',
                            data: JSON.stringify({ data: data }),
                            success: function (response) {
                                var properties = response.properties;
                                var markers = properties.map(function (property) {
                                    return new google.maps.Marker({
                                        position: property['coordinate'],
                                    });
                                });
                                markerCluster.clearMarkers();
                                markerCluster.addMarkers(markers);

                                var property_ids = properties.map(function (property) {
                                    return property['_id']
                                });
                                updatePropertyIds(property_ids);

                                if (properties.length > 0) {
                                    city.coordinate = center;
                                }
                            },
                            //error: function (err) {
                            //    //console.log(err);
                            //}
                        });
                    }
                }
            });

            //Split(['#map', '#requests'], { sizes: [50, 50] })
        },
    };
</script>


<style scoped>
    .autocompletePlaceholder::-webkit-input-placeholder {
        color: #8898aa
    }

    div /deep/ .pac-container {
        z-index: 9999999999999999999999999999999999 !important;
        top: 47px !important;
        left: 0 !important;
    }
</style>

<style>
    html.mapPage {
        overflow: hidden;
    }

    body.mapPage {
        height: 100%;
        overflow-y: scroll;
    }

    #toggleButton {
        display: none;
        position: fixed;
        margin: 0 auto;
        bottom: 30px;
        z-index: 99;
        left: 9px;
    }

    .filterButton {
        display: block;
        position: fixed;
        margin: 0 auto;
        top: 60px;
        z-index: 99;
        left: 9px;
    }

    .Gmap {
        width: 59.7%;
        height: 95%;
        bottom: 0;
        position: absolute;
    }

    .properties {
        width: 39.7%;
        float: right;
        -ms-overflow-x: hidden;
        -ms-overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        height: 100%;
        position: relative;
        padding: 10px;
        padding-top: 0px;
        padding-bottom: 10px;
    }

    #mapInput {
        display: none;
    }

    @media screen and (max-width: 768px) {
        #toggleButton {
            display: block;
        }

        .filterButton {
            left: unset;
            right: 9px;
        }

        .Gmap {
            display: none;
        }

        .properties {
            width: 100%;
        }

        #mapInput {
            display: block;
        }

        .showMap {
            display: block;
            width: 100%;
        }

        .hideMap {
            display: none;
        }

        .badged {
            margin: 10px;
        }

        .dropdown-menu {
            -webkit-box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1) !important;
            box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1) !important;
        }
    }
</style>